.upgrade-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.upgrade-popup {
  width: 90%;
  max-width: 500px;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upgrade-popup-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.upgrade-feature {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.feature-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.upgrade-cost {
  font-size: 20px;
  margin-top: 20px;
}

.continue-button {
  font-size: 18px;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  background-color: #3f51b5; /* primary color */
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continue-button:hover {
  background-color: #303f9f; /* darker primary color */
}
