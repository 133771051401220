body {
  font-family: "Roboto", sans-serif;
}

.addReminder-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.addReminder-h1 {
  flex-grow: 1;
  text-align: center;
  font-size: 1.5em;
  color: #fafafa;
}

#description {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  margin-top: 10px;
}

.addReminder-venue-h3,
.addReminder-venue-h4 {
  margin: 10px 0;
  color: #757575;
}

.top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px;
  background-color: #3f51b5;
  color: #fff;
}

.left-icon,
.right-icons {
  display: flex;
  align-items: center;
}

.left-icon i,
.right-icons i {
  margin-right: 10px;
  color: #fff;
}

.text-buttons {
  text-decoration: none;
  color: #3f51b5;
  background-color: #fafafa;
  padding: 3px 7px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.text-buttons:hover {
  background-color: #f0f0f0;
}

input[type="text"],
input[type="date"],
select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  background-color: #fafafa;
}

input[type="text"]:focus,
input[type="date"]:focus,
select:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(63, 81, 181, 0.5); /* Same color as .addReminder-h1 but with transparency */
}

#sendButton {
  background-color: #3f51b5;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#sendButton:hover {
  background-color: #303f9f;
}

.addReminder-body div.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.addReminder-body div.checkbox-container input[type="checkbox"] {
  margin-right: 5px;
}

/* Hide the default checkbox */
.addReminder-body div.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Create a new checkbox */
.addReminder-body div.checkbox-container input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 20px; /* you can change this to make checkbox larger or smaller */
  height: 20px; /* you can change this to make checkbox larger or smaller */
  margin-right: 5px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  vertical-align: middle;
}

/* Style the new checkbox when checked */
.addReminder-body
  div.checkbox-container
  input[type="checkbox"]:checked
  + label:before {
  background-color: #3f51b5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E"); /* a checkmark icon */
  background-repeat: no-repeat;
  background-position: center center;
}
