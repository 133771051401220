.ai-container {
  display: flex;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.ai-items {
  margin-top: 10px;
  flex: 1;
  padding: 15px;
  overflow-y: scroll;
  height: 65vh;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ai-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  position: relative;
}

.ai-item:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.right-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* align left for left-content and right for right-content */
}

.svg-and-birthday {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ai-name {
  font-size: 1.2em;
  font-weight: 500;
  color: #3f51b5;
}

.ai-relationship {
  font-size: 0.8em;
  color: #757575;
}

.ai-style {
  font-size: 0.8em;
  color: #757575;
}

.ai-birthday {
  font-size: 0.8em;
  font-weight: 500;
  color: #757575;
}

.ai-days {
  font-size: 1.2em;
  color: #3f51b5;
  font-weight: bold;
}
.ai-days-left {
  font-size: 0.8em;
  color: #757575;
}

.ai-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  background-color: black;
}

.ai-item-title {
  flex: 1;
  font-weight: 500;
  color: #3f51b5;
}

.ai-buy-button,
.upgrade-button {
  color: #3f51b5;
  background-color: #fafafa;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  text-decoration: none;
}

.ai-buy-button:hover,
.upgrade-button:hover {
  background-color: #e0e0e0;
}

.ai-price {
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #3f51b5;
}

.top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #3f51b5;
  color: #fff;
}

.top-panel-title {
  flex-grow: 1;
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 0.9em;
}

.left-icon,
.right-icons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.plus-icon {
  margin-left: 5px;
  cursor: pointer;
}

.left-icon i,
.right-icons i {
  margin-right: 10px;
  color: #fff;
}

.ai-upgrade-text {
  font-size: 0.9em;
  color: #757575;
}

.top-buttons {
  position: absolute;
  top: -10px;
  left: -10px;
  display: flex;
  justify-content: space-between;
}

.edit-button,
.delete-button {
  background: #fff;
  border: none;
  padding: 2px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin-right: 1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.edit-button:hover,
.delete-button:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.edit-button svg,
.delete-button svg {
  fill: #3f51b5; /* Change this color to match the color of your SVG icons */
  width: 90%;
  height: 90%;
}

@media (max-width: 768px) {
  .ai-items {
    height: 85vh;
  }
}
