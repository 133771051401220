body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
}

.column {
  background-color: rgb(255, 255, 255);
  width: 60vw;
  height: 75vh;
  margin-top: 7vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: auto;
}

@media (max-width: 768px) {
  .column {
    width: 100vw;
    height: 100vh;
    margin-top: 0vh;
    border: none;
  }
}
